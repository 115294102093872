#game {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;

  .counter_container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    color: grey;
    font-size: 14px;

    p {
      cursor: default;

      span {
        width: 60px;
        display: inline-block;
      }
    }
  }

  #level-indicator {
    position: fixed;
    top: 0;
    left: 0;
  }

  &.pointer {
      cursor: pointer;
  }

  .modal {
      background-color: white;
      padding: 20px;
      box-shadow: 4px 4px 20px rgba(0,0,0,.2);

      div {
        display: flex;
      }

      h1 {
        margin-bottom: 15px;
      }
      input {
        margin-right: 10px;
      }
      p {
        margin-bottom: 10px;

        &.italic {
          font-style: italic;
        }

        &:last-child {
          margin: 0;
        }
      }

      button {
        border: none;
        margin: 10px 0;
        background: black;
        padding: 20px;
        color: white;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
      }
  }

  .img_container {
      height: 76px;
      width: 76px;
      position: fixed;
      transform: translate(0px, 0px);
      transition: transform .5s ease-in-out;

      img {
          height: 100%;
          width: 100%;
          position: absolute;

          &:last-child {
            z-index: -3;
          }
      }
  }

  .images {
    display: none;
  }
}